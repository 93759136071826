<template>
  <div id="dashboard-page">
    <h1 id="page-title" class="h2">Dashboard</h1>

    <div class="row no-gutters mb-4">
      <div class="col bg-white" style="border-radius: 10px 0 0 10px">
        <div id="welcome-card" class="card h-100" :style="`background-image:url(${require('@/assets/img/brain.svg')})`">
          <div class="card-body" style="min-height: 200px;">
            <h2>Welkom Nienke</h2>
            <p>Het lijkt erop dat er nog geen patiënten in je bestand staan.<br>Laten we daar verandering in brengen!</p>
            <button type="button" class="btn btn-info">Patiënt uitnodigen</button>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card h-100" style="border-radius: 0 10px 10px 0">
          <div class="card-body">
            <ul>
              <li>A</li>
              <li>B</li>
              <li>C</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col mb-4">
        <div class="card">
          <div class="card-body" style="height: 200px">
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card">
          <div class="card-body" style="height: 200px">
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card">
          <div class="card-body" style="height: 200px">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col mb-4">
        <div class="card">
          <div class="card-body" style="height: 300px">
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card">
          <div class="card-body" style="height: 300px">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dashboard'
}
</script>

<style lang="scss">
#page-title {
  margin-bottom: 40px;
}

.card {
  border-radius: 10px;
  border: none;
  box-shadow: 0 10px 20px rgba(0,0,0, 0.03);
}

#welcome-card {
  background-color: #428AF7;
  color: #fff;
  background-repeat: no-repeat;
  background-position: bottom -26px right 10px;

  .btn {
    text-transform: initial;
    background-color: #27D0E8;
    font-weight: 400;
  }
}
</style>
